define("@huvepharma/huvepharma-aviapp-components/templates/components/tables/huve-gfr-daily-table-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "a3a2hLzK",
    "block": "[[[1,\"\\n\"],[10,\"tr\"],[14,0,\"gfr-table-header\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"    \"],[10,\"th\"],[15,\"colspan\",[52,[30,1,[\"children\"]],[30,1,[\"children\",\"length\"]]]],[14,0,\"title\"],[14,\"align\",\"left\"],[12],[1,\"\\n      \"],[10,\"strong\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\"],[10,\"tr\"],[14,0,\"gfr-table-header\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[41,[30,2,[\"sections\"]],[[[42,[28,[37,1],[[28,[37,1],[[30,2,[\"sections\"]]],null]],null],null,[[[1,\"          \"],[10,\"th\"],[15,\"colspan\",[30,3,[\"size\"]]],[12],[1,\"\\n            \"],[10,\"strong\"],[12],[1,[30,3,[\"title\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[3]],null]],[]],null]],[2]],null],[13],[1,\"\\n\"],[10,\"tr\"],[14,0,\"gfr-table-header\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[41,[30,4,[\"children\"]],[[[42,[28,[37,1],[[28,[37,1],[[30,4,[\"children\"]]],null]],null],null,[[[1,\"        \"],[10,\"th\"],[14,0,\"children\"],[15,\"data-test-id\",[28,[37,4],[[30,5,[\"id\"]],\"-header\"],null]],[12],[1,\"\\n          \"],[10,1],[12],[1,[30,5,[\"title\"]]],[13],[1,\"\\n\"],[41,[30,5,[\"selectors\"]],[[[1,\"            \"],[1,[28,[35,5],null,[[\"testId\",\"options\",\"field\",\"onChange\"],[[30,5,[\"id\"]],[30,5,[\"selectors\"]],[30,5],[28,[37,6],[[30,0],\"handleUnitChange\"],null]]]]],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n\"]],[5]],null]],[]],[[[1,\"      \"],[10,\"th\"],[14,0,\"subtitle\"],[12],[1,\"\\n        \"],[1,[30,4,[\"subtitle\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]]],[4]],null],[13]],[\"field\",\"field\",\"section\",\"field\",\"child\"],false,[\"each\",\"-track-array\",\"data\",\"if\",\"concat\",\"unit-select\",\"action\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/tables/huve-gfr-daily-table-header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});