define("@huvepharma/huvepharma-aviapp-components/components/form/date-picker", ["exports", "@ember/component", "@ember/runloop", "jquery", "moment", "@huvepharma/huvepharma-aviapp-components/helpers/time-helper"], function (_exports, _component, _runloop, _jquery, _moment, _timeHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  var _default = _component.default.extend({
    closeOnSelectDate: true,
    showToday: true,
    isDefaultEmpty: false,

    /**
     * Initialise date picker and set action on pick event
     */
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (this.unavailableDates !== undefined) {
        (0, _runloop.scheduleOnce)('afterRender', this, function () {
          Promise.resolve(_this.unavailableDates).then(function (value) {
            return _this.setupDatePicker(value);
          });
        });
      } else {
        this.setupDatePicker(null);
      }
    },

    /**
     * Convert item to UTC date
     * @param {String} itemName Name of item that should be converted
     */
    generateConversion: function generateConversion(itemName) {
      return this.get(itemName) ? _timeHelper.default.addUTCOffsetToDate(+this.get(itemName)) : null;
    },
    generateNavigation: function generateNavigation() {
      // Style and add test IDs to the prev / next buttons
      var pickerNavPrevElement = (0, _jquery.default)('.picker__nav--prev');
      var pickerNavNextElement = (0, _jquery.default)('.picker__nav--next');

      if (!pickerNavPrevElement.has('svg').length) {
        pickerNavPrevElement.append('<svg class="previous"><use xlink:href="#next-circle"></use></svg>').attr('data-test-id', 'date-picker-previous-button');
      }

      if (!pickerNavNextElement.has('svg').length) {
        pickerNavNextElement.append('<svg><use xlink:href="#next-circle"></use></svg>').attr('data-test-id', 'date-picker-next-button');
      }
    },
    generateDropdowns: function generateDropdowns() {
      // Remove any previous styling and then rerender
      (0, _jquery.default)('.picker__wrapper__select--year').remove();
      (0, _jquery.default)('.picker__wrapper__select--month').remove(); // Move the picker year and month dropdowns into a wrapper for styling

      (0, _jquery.default)('.picker__select--year').after("\n    <span class=\"field-select picker__wrapper__select--year\">\n      <span class=\"dropdown-button\">\n        <svg><use xlink:href=\"#arrow-down\"></use></svg>\n      </span>\n    </span>\n    ").appendTo((0, _jquery.default)('.picker__wrapper__select--year'));
      (0, _jquery.default)('.picker__select--month').after("\n      <span class=\"field-select picker__wrapper__select--month\">\n        <span class=\"dropdown-button\">\n          <svg><use xlink:href=\"#arrow-down\"></use></svg>\n        </span>\n      </span>\n      ").appendTo((0, _jquery.default)('.picker__wrapper__select--month'));
    },

    /**
     * Find and restyle picker elements
     * @param {Array<Date>} unavailable Dates that may not be selected
     * @param {<Date>} marker Already selected visit
     */
    restylePickerDayElems: function restylePickerDayElems(unavailable, marker) {
      var _this2 = this;

      // Restyle the picker__day elements
      (0, _jquery.default)('.picker__table').find('.picker__day').each(function (i, val) {
        (0, _jquery.default)(val).attr('data-test-id', 'date-picker-' + (0, _moment.default)((0, _jquery.default)(val).attr('aria-label')).format('DD-MM-YYYY'));
        var currentDay = parseInt((0, _jquery.default)(val).attr('data-pick'), 10); // Restyle unavailable days

        if (unavailable && unavailable.map(function (date) {
          return +date;
        }).includes(currentDay)) {
          (0, _jquery.default)(val).addClass("".concat(_this2.unavailableClass, " picker__day--disabled"));
        } // Restyle the marker


        if (marker && marker.valueOf() === currentDay) {
          (0, _jquery.default)(val).addClass("".concat(_this2.markerClass, " picker__day--disabled"));
        }
      });
    },
    addDataTestIds: function addDataTestIds() {
      // Add test IDs to the available buttons
      (0, _jquery.default)('.picker__button--today').attr('data-test-id', 'date-picker-today-button');
      (0, _jquery.default)('.picker__button--close').attr('data-test-id', 'date-picker-close-button');
      (0, _jquery.default)('.picker__button--clear').attr('data-test-id', 'date-picker-clear-button');
    },
    handleToday: function handleToday() {
      if (!this.showToday) {
        (0, _jquery.default)('.picker__button--today').addClass('hide');
      }
    },

    /**
     * Setup the date picker
     * @param {Array<Moment>} unavailableDates Dates that may not be selected
     */
    setupDatePicker: function setupDatePicker(unavailableDates) {
      var _this3 = this;

      var marker = this.generateConversion('markerDate'),
          minDate = this.generateConversion('minDate'),
          maxDate = this.generateConversion('maxDate');
      var unavailable = unavailableDates ? unavailableDates.map(_timeHelper.default.addUTCOffsetToDate).filter(function (i) {
        return !marker || i !== marker.valueOf();
      }) : null;
      var options = {
        selectMonths: true,
        selectYears: true,

        /**
         * Refocus on picker when the outside is clicked
         * If not enabled, dropdown select is disabled
         */
        onClose: function onClose() {
          _this3.picker.open();
        },
        onRender: function onRender() {
          _this3.generateNavigation();

          _this3.generateDropdowns();

          _this3.addDataTestIds();

          _this3.handleToday(); // Remove pickaday close button


          (0, _jquery.default)('.picker__button--close').remove();

          _this3.forceActive();

          _this3.restylePickerDayElems(unavailable, marker);
        }
      };
      this.handleOptions(options, minDate, maxDate, unavailable, marker); // Init picker

      var picker = (0, _jquery.default)(this.element).pickadate(options).pickadate('picker');
      this.set('picker', picker);
      picker.on('set', function (date) {
        if (date.select) {
          // When clicking on a day in the date picker, it'll be a number and
          // midnight in local time.
          var selected = _moment.default.utc(_timeHelper.default.subtractUTCOffsetFromEpoch(+date.select));

          var currentDateProp = _this3.date ? _this3.convertIncomingDate(_this3.date, options.disable) : null; // Only update 'date' if it's different from the
          // selected date, mostly to prevent multiple edits
          // to 'date' in a single render

          var differentDate = date.select !== currentDateProp; // Invoke changeDate function if given else just change date
          // As 'day' is a unix timestamp and already in UTC time,
          // we need to first turn it into a UTC date before
          // extracting the dateZoneInvariant

          if (_this3.changeDate && differentDate) {
            var _this3$changeDate;

            (_this3$changeDate = _this3.changeDate) === null || _this3$changeDate === void 0 ? void 0 : _this3$changeDate.call(_this3, _timeHelper.default.extractDateZoneInvariant(selected).toDate());
          } else if (differentDate) {
            _this3.set('date', _timeHelper.default.extractDateZoneInvariant(selected).toDate());
          } // Close modal when selecting a date


          if (_this3.closeOnSelectDate) {
            var _this3$closeAction;

            (_this3$closeAction = _this3.closeAction) === null || _this3$closeAction === void 0 ? void 0 : _this3$closeAction.call(_this3);
          }
        }
      }); // Select the initial date if it isn't set before
      // When the date is set select given date without
      // triggering the on:select method

      picker.set('select', this.convertIncomingDate(this.date, options.disable), {
        muted: !!this.date
      }); // If the picker is required to be empty by default

      if (this.isDefaultEmpty) {
        this.handleDefaultEmpty(picker);
      }

      this.picker.open(false);
    },

    /**
     * Handle picker when needs to be empty by default
     * @param {Object} options has required features for datepicker
     * @param {Object<Date>} minDate min allowed date
     * @param {Object<Date>} maxDate max allowed date
     * @param {Array<Date>} unavailable Dates that may not be selected
     * @param {<Date>} marker Already selected visit
     */
    handleOptions: function handleOptions(options, minDate, maxDate, unavailable, marker) {
      options.today = this.todayLabel;
      options.monthsFull = _moment.default.months();
      options.monthsShort = _moment.default.monthsShort();
      options.weekdaysFull = _moment.default.weekdays();
      options.weekdaysShort = _moment.default.weekdaysShort(); // If a rendering container has been specified - apply it

      options.container = this.renderContainer || '#modal-wormhole'; // Using pickadate as an embedded widget instead of a popup

      options.klass = {
        picker: 'picker picker--opened',
        opened: '__always-open__',
        active: 'picker__input--active'
      }; // Limit date-picker by min and max if required

      if (minDate) {
        options.min = minDate;
      }

      if (maxDate) {
        options.max = maxDate;
      } // Disable unavailable-dates and marker


      options.disable = [];

      if (unavailable) {
        var _options$disable;

        (_options$disable = options.disable).push.apply(_options$disable, _toConsumableArray(unavailable));
      }

      if (marker) {
        options.disable.push(marker);
      }
    },

    /**
     * Handle picker when needs to be empty by default
     * @param {Object<Picker>} picker Name of item that should be converted
     */
    handleDefaultEmpty: function handleDefaultEmpty(picker) {
      picker.set('select', undefined); // Setting 'highlight' to undefined results in not being able to
      // navigate between months, so we disable highlighting
      // via CSS

      (0, _jquery.default)('.picker').addClass('disable-highlighted'); // Otherwise set the selected-date
    },
    forceActive: function forceActive() {
      // Force them to be enabled
      (0, _jquery.default)('.picker__select--year').removeAttr('disabled');
      (0, _jquery.default)('.picker__select--month').removeAttr('disabled');
    },

    /**
     * Will destroy element
     */
    willDestroyElement: function willDestroyElement() {
      var picker = this.picker;

      if (picker && picker.stop) {
        picker.stop();
      }
    },
    convertIncomingDate: function convertIncomingDate(date, disabledDates) {
      // Selected date for datepicker needs to be in the correct timezone.
      // If date is string, it means it's been formatted by the locale-date
      // helper and needs to be parsed in UTC time again
      var selectDate = typeof date === 'string' ? _timeHelper.default.extractDateZoneInvariant(_moment.default.utc(date, this.dateFormat || this.get('session.me.dateFormat'))) : _moment.default.utc(date); // Set the currently selected date
      // We need to add the current UTC offset to the given date because
      // internally, pickadate is going to take this date and call
      // new Date(date).setHours(0, 0, 0) on it. This means that given a
      // date like: Sun Dec 10 2017 13:00:00 GMT-1100 (SST)
      // it will interpret it as: Sun Dec 10 2017 00:00:00 GMT-1100 (SST)
      // Meaning that in this case, the UTC day will no longer be Monday
      // but Sunday...

      return this.findNearestAvailableDay(_timeHelper.default.addUTCOffsetToDate(selectDate), disabledDates);
    },

    /**
     * Find the nearest day in the future where a visit hasn't
     * been planned yet
     * @param {Date} date
     * @param {Array<Date>} disabled
     * @returns {Int} unix timestamp
     */
    findNearestAvailableDay: function findNearestAvailableDay(date, disabled) {
      var ONE_DAY_MS = 86400000;
      var MAX_DAYS_IN_FUTURE = 100;
      var dates = disabled.map(function (d) {
        return +d;
      });
      var day = new Date(+date).setHours(0, 0, 0, 0);
      var step = 0;

      while (dates.includes(day) && step < MAX_DAYS_IN_FUTURE) {
        day += ONE_DAY_MS;
        step += 1;
      }

      return day;
    }
  });

  _exports.default = _default;
});