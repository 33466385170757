define("@huvepharma/huvepharma-aviapp-components/templates/components/buttons/button-base", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hJjd83Ju",
    "block": "[[[10,\"button\"],[15,\"data-test-id\",[30,1]],[15,0,[36,0]],[15,\"disabled\",[36,1]],[15,\"onclick\",[28,[37,2],[[30,0],[28,[37,3],[[33,4]],null]],null]],[12],[18,2,null],[13]],[\"@testId\",\"&default\"],false,[\"class\",\"disabled\",\"action\",\"optional\",\"onClick\",\"yield\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/buttons/button-base.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});