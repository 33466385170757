define("@huvepharma/huvepharma-aviapp-components/templates/components/scoring/scoring-toggle-input", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "er/hNONM",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,\"h3\"],[16,\"data-test-id\",[30,2]],[16,0,[29,[[52,[51,[30,3]],\"clickable\"]]]],[4,[38,2],[[30,0],\"showInfo\"],null],[12],[1,\"\\n    \"],[1,[28,[35,3],[[30,1]],null]],[1,\"\\n\"],[41,[51,[30,3]],[[[1,\"      \"],[10,0],[14,0,\"info-icon\"],[12],[1,\"\\n        \"],[10,\"svg\"],[14,\"role\",\"button\"],[12],[1,\"\\n          \"],[10,\"use\"],[14,\"xlink:href\",\"#info\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null],[10,0],[14,0,\"scores\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[33,6]],null]],null],null,[[[1,\"    \"],[11,1],[24,\"role\",\"button\"],[16,\"aria-label\",[29,[[30,1],\" \",[30,4]]]],[16,0,[29,[\"score \",[52,[28,[37,7],[[30,4],[33,8]],null],\"active\"]]]],[16,\"data-test-id\",[29,[[52,[30,2],[28,[37,9],[[30,2],\"-\",[30,4],\"-button\"],null],[28,[37,9],[[30,4],\"-button\"],null]]]]],[4,[38,2],[[30,0],\"toggleScore\",[30,4]],null],[12],[1,\"\\n      \"],[1,[28,[35,10],[[30,4]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[4]],null],[13],[1,\"\\n\"]],[\"@title\",\"@testIdPrefix\",\"@hideInfo\",\"score\"],false,[\"if\",\"unless\",\"action\",\"module-name\",\"each\",\"-track-array\",\"scores\",\"case-insensitive-eq\",\"comparableCurrentScore\",\"build-test-id\",\"format-uppercase\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/scoring/scoring-toggle-input.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});